<template>
  <div class="container">
    <template v-if="!getLoadings.tenant && tenant">
      <div class="d-flex justify-content-between">
        <h2 class="fw-bold heading text-black mb-4" v-if="tenant.name">
          {{ $t("Tenant") + ":" + tenant.name }}
        </h2>
        <div>
          <BButton variant="white" :disabled="getLoadings.cancelAssigning">
            {{ $t("Back to overview") }}
          </BButton>
        </div>
      </div>
    </template>
    <Spinner v-else />
  </div>
</template>

<script>
import Spinner from "@/components/ui/Spinner.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "TenantDetail",
  components: {
    Spinner,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters("tenant", ["tenant"]),
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    tenant_id() {
      return this.$route.params.tenant_id;
    },
  },
  methods: {
    ...mapActions("tenant", ["getTenantDetail"]),
  },
  created() {
    this.getTenantDetail(this.tenant_id);
  },
};
</script>
